@tailwind base;
@tailwind components;
@tailwind utilities;

.poppins {
  font-family: "Poppins";
}

@font-face {
  font-family: "Poppins";
  font-weight: 100;
  src: url("../font/Poppins/Poppins-Thin.ttf") format("truetype");
}

@font-face {
  font-family: "Poppins";
  font-weight: 200;
  src: url("../font/Poppins/Poppins-ExtraLight.ttf") format("truetype");
}

@font-face {
  font-family: "Poppins";
  font-weight: 300;
  src: url("../font/Poppins/Poppins-Light.ttf") format("truetype");
}

@font-face {
  font-family: "Poppins";
  font-weight: 400;
  src: url("../font/Poppins/Poppins-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Poppins";
  font-weight: 500;
  src: url("../font/Poppins/Poppins-Medium.ttf") format("truetype");
}

@font-face {
  font-family: "Poppins";
  font-weight: 600;
  src: url("../font/Poppins/Poppins-SemiBold.ttf") format("truetype");
}

@font-face {
  font-family: "Poppins";
  font-weight: 700;
  src: url("../font/Poppins/Poppins-Bold.ttf") format("truetype");
}

@font-face {
  font-family: "Poppins";
  font-weight: 800;
  src: url("../font/Poppins/Poppins-ExtraBold.ttf") format("truetype");
}

@font-face {
  font-family: "Poppins";
  font-weight: 900;
  src: url("../font/Poppins/Poppins-Black.ttf") format("truetype");
}

.p-dropdown .p-dropdown-label {
  white-space: normal;
  padding-top: 14px;
  padding-right: 0px;

  font-weight: 600;
  font-family: "Poppins";
}

.p-dropdown-trigger-icon {
  font-size: 0.7rem !important;
  font-weight: 600;
  font-family: "Poppins";
  padding-left: 0px;
}

.p-dropdown-items-wrapper {
  max-width: 500px;
  font-weight: 600;
  font-family: "Poppins";
}

.p-dropdown-items-wrapper li.p-dropdown-item {
  white-space: pre-wrap;
  font-size: 0.875rem;
  font-weight: 600;
  font-family: "Poppins";
  padding: 8px 20px !important;
}

.p-0 .p-inputtext {
  padding: 0 !important;
}

.text-white .p-inputtext,
.text-white .p-dropdown-trigger-icon {
  color: white;
}

.border-0 .p-dropdown {
  border: 0px solid;
}

.default-chakra-input {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  width: 100%;
  -webkit-padding-start: 1rem;
  padding-inline-start: 1rem;
  -webkit-padding-end: 1rem;
  padding-inline-end: 1rem;
  border-radius: 0.375rem;
  outline: 2px solid transparent;
  outline-offset: 2px;
  border: 1px solid #ced4da;
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
  padding-top: 12px;
  padding-bottom: 12px;
}

.datepicker {
  padding: 11px !important;
  border-color: #cbd5e0;
}

.ReactTags__tags {
  position: relative;
}

.ReactTags__tagInput {
  padding: 0px;
  border-radius: 5px;
  border: none;
  width: 50%;
}

.ReactTags__tagInputField {
  padding: 12px;
  border-radius: 5px;
  border: 1px solid #cbd5e0;
  width: 100%;
}

.ReactTags__selected span.ReactTags__tag {
  border: 10px solid #ddd;
  /* background: #63bcfd;
  color: white;
  font-size: 24px;
  display: inline-block;
  padding: 5px;
  margin: 0 5px;
  border-radius: 2px; */
}
.ReactTags__selected a.ReactTags__remove {
  color: #aaa;
  margin-left: 5px;
  cursor: pointer;
}

.tag-input {
  padding: 0.5px;
  border: 1px solid #cbd5e0;
  border-radius: 10px;
}

.ql-tooltip {
  left: 0 !important;
}

.rs-picker-date-menu {
  z-index: 9999 !important;
}

.rs-picker-daterange-menu {
  z-index: 9999 !important;
}

.custom-dropdown .p-inputtext {
  padding-top: 7px;
  padding-bottom: 7px;
  padding-left: 15px;
}
